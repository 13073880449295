//IMPORTANT: this page must be called "signup-success" in the URL, because the app checks for that URL to determine if something has been signed up.
//this is being resolved here: 

import { ConfirmationPage } from "@components/web/ConfirmationPage";
import HeaderDefaultWithLogo from "@components/web/HeaderDefaultWithLogo";
import { useLocalization } from "@hooks/localization";

export default () => {
    const t = useLocalization();
    return <>
        <HeaderDefaultWithLogo />
        <ConfirmationPage
            showButton={false}
            titleH2={t("Confirmation_Credits_SuccesMessageTitle")}
            text={<div dangerouslySetInnerHTML={{ __html: t("Confirmation_Credits_SuccesMessage") }} />} />
    </>
}

