import { TranslationKey } from "@utils/i18n";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type LocalizationFunction = 
(<T extends TranslationKey>(...params: T) => string);

export const useLocalization = (): LocalizationFunction => {
    const {t} = useTranslation(undefined);
    const callback = useCallback(
        (key: any, values: any) => key ?
            t(key, values) :
            "",
        [t]) as LocalizationFunction;
    if(typeof window === "undefined")
        return () => " ";

    return callback;
}