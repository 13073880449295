import { Button, ButtonTypeMap, ExtendButtonBaseTypeMap, LinearProgress } from "@material-ui/core";
import { OverrideProps } from "@material-ui/core/OverridableComponent";
import { useMemo, useState } from "react";
import styles from "./Button.module.scss";

export default function ReshopperButton(
	props: OverrideProps<ExtendButtonBaseTypeMap<ButtonTypeMap>, 'a'>
) {
	const sizeClassName = (styles as any)[props.size as any];
	const [isLoading, setIsLoading] = useState(false);2
	const computedDisabled = useMemo(
		() => isLoading || props.disabled,
		[isLoading, props.disabled]);

	const onClick = async (e: React.MouseEvent<any>) => {
		if(!props.onClick)
			return;

		setIsLoading(true);
		try {			
			await Promise.resolve(props.onClick(e));
		} finally {
			setIsLoading(false);
		}
	}

	return <Button
		{...props as any}
		className={`${props.className} ${styles.button} ${sizeClassName} ${computedDisabled ? styles.disabled : ""}`}
		disabled={computedDisabled}
		onClick={onClick}
	>
		{isLoading && <LinearProgress 
			variant="indeterminate"
			className={styles.progress}
			classes={{
				bar: styles.bar
			}}
		/>}
		{props.children}
	</Button>;
}