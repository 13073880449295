import AnimatedCheckmark from "@components/web/AnimatedCheckmark";
import ReshopperButton from "@components/web/Button";
import { withWebLayout } from "@components/web/Decorators";
import { createMessage } from "@utils/iframe";
import { navigateToApp } from "@utils/navigation";
import { useEffect } from "react";
import styles from "./ConfirmationPage.module.scss";

export const ConfirmationPage = withWebLayout(function (props: {
    title?: string,
    titleH2?: string,
    text?: string | React.ReactElement,
    showButton?: true | false | undefined,
    isFailure?: boolean
}) {
    useEffect(
        () => {
            if(!window.parent)
                return;

            //in case we are loading this page from an iframe, we need to send a message to the parent window so that it can react to the confirmation.
            window.parent.postMessage(
                createMessage(
                    props.isFailure ?
                        "confirmation-failure" :
                        "confirmation-success",
                    null),
                window.location.origin);
        },
        []);

    return <div className={styles.confirmationPage}>
        {!props.isFailure && <AnimatedCheckmark />}
        
        {props.title && <h1>{props.title}</h1>}
        {props.titleH2 && <h2>{props.titleH2}</h2>}
        {props.text && <p>{props.text}</p>}

        {props.showButton !== false && <ReshopperButton
            onClick={() => navigateToApp()}
            style={{
                marginTop: 32
            }}
        >
            Open Reshopper
        </ReshopperButton>}
    </div>
});