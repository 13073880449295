import Logo, { LogoVariants } from "@components/Logo";
import styles from "../web/HeaderDefaultWithLogo.module.scss";

export default function HeaderDefaultWithLogo(props: {
	children?: React.ReactNode,
	logoVariant?: LogoVariants,
	showH1?: boolean,
	customH1?: string | React.ReactNode,
	text?: string | React.ReactElement,
	logoStyle?: React.CSSProperties
}) {
	let defaultH1 = <h1>{props.customH1 ? props.customH1 : <>Danmarks største<br />markedsplads for børnefamilier</>}</h1>;

	return <header className={styles.headerDefault}>
		<div>
			<Logo variant={props.logoVariant ?? "icon-beige"} href="/" style={props.logoStyle} />
			{!!props.showH1 && defaultH1}
			{!!props.text && <div className={styles.headerText}>{props.text}</div>}
		</div>
	</header>
}


