import { delay } from "@utils/miscellaneous";

export type WindowMessage<K, T = null> = {
    origin: "reshopper",
    type: K,
    data: T
}

export type WindowMessages = 
    WindowMessage<"confirmation-failure"> |
    WindowMessage<"confirmation-success">;

export function getMessage(data: any): WindowMessages|null {
    if(typeof data !== "object")
        return null;

    if(data.origin !== "reshopper")
        return null;

    return data;
}

export function createMessage<T extends WindowMessages>(key: T["type"], data: T["data"]) {
    return {
        data,
        origin: "reshopper",
        type: key
    } as WindowMessages;
}

export async function waitForIFrameToLoad(iframe: HTMLIFrameElement) {
    let isLoaded = false;

    function onLoad() {
        isLoaded = true;
    }

    try {
        iframe.addEventListener('load', onLoad);

        while (!isLoaded) {
            try {
                const content = iframe.contentDocument || iframe.contentWindow?.document;
                if (content?.readyState !== 'complete')
                    continue;

                isLoaded = true;
            } finally {
                await delay(100);
            }
        }
    } finally {
        iframe.removeEventListener('load', onLoad);
    }
}